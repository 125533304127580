*,
*:after,
*:before {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  position: relative;
  margin: 0;
}

body {
  font-family: $font-regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: $black;
  background: $background-admin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// React-specific

main {
  height: 100vh;
}

// Images
img,
svg {
  max-width: 100%;
  vertical-align: middle;
}

// Lists
ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

[hidden],
.hidden {
  display: none;
}

// Prevent web-accessibility border
input:focus,
button:focus,
a:focus {
  outline: 0;
  box-shadow: none;
}
