/*
    FORMS
*/

// Prevent background autofill Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

// default label
label {
    font-size: rem(13);
    font-family: $font-semibold;
    line-height: 1;
    display: inline-block;
}

form {
    width: 100%;
    display: block;
    transition: opacity .3s ease-out;
    will-change: opacity;

    &.fadeOut {
        pointer-events: none;
        user-select: none;
        opacity: 0;
    }

    &.containers-form {
        max-width: 450px;

        &--view {
            max-width: 740px;
        }
    }

}

.form-group {
    width: 100%;
    position: relative;

    label {
        line-height: rem(22);
        margin: 0 0 10px;
        display: block;

        &.required {

            &:after {
                content: '*';
                color: $alert;
            }
        }
    }

    /*
       .form-group followed by a .form-group || .w-50
    */
    + .form-group,
    + .w-50 {
        margin-top: rem(16);
    }

    // inputs
    input:not([type=radio]):not([type=checkbox]) {
        font-family: $font-regular;
        font-size: rem(13);
        box-shadow: none;
        width: 100%;
        background: 0;
        height: 41px;
        border-radius: 7px;
        color: $black;
        border: 1px solid $grey-300;
        padding: 0 45px;
        transition: border .2s ease;

        &.form-content {
            padding: 10px 10px 10px 20px;
        }

        &::placeholder {
            color: $grey-400;
        }

        &:not(:placeholder-shown),
        &:hover,
        &:focus,
        &:read-only {
            border: 1px solid $grey-400;
            color: $black;
        }

        &.read-only {
            border: 1px solid $grey-400;
            color: $black;
            background: $grey-300;
        }

        &.invalid-field {
            border: 1px solid $alert;
            color: $alert;
        }
    }

    .icon-container {
        position: relative;
    }

    .icon-left,
    .icon-right {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .icon-left {
        left: 10px;
        user-select: none;
    }

    .icon-right {
        right: 10px;
    }
}

.w-50 + .containers-title {
    margin: 40px 0;
}

.custom-checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    // Default state
    input[type='checkbox'] {
        cursor: pointer;
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .fake-checkbox {
        width: 15px;
        height: 15px;
        position: relative;
        border: 1px solid $grey-400;
        border-radius: 3px;
        background: 0;
        transition: background .2s ease;

        &:before {
            content: '';
            width: 10px;
            height: 10px;
            background: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    // Checked state
    input[type='checkbox']:checked + .fake-checkbox {
        background: $success;
        border: 1px solid $success;

        &:before {
            content: '';
            background: url('../assets/images/icons/icon-check.svg') no-repeat center center;
            background-size: contain;
        }
    }

    // data-state : empty / full / some
    input[type='checkbox'][data-state='full'] + .fake-checkbox {
        background: $success;
        border: 1px solid $success;

        &:before {
            content: '';
            background: url('../assets/images/icons/icon-check.svg') no-repeat center center;
            background-size: contain;
        }
    }

    input[type='checkbox'][data-state='some'] + .fake-checkbox {
        background: $success;
        border: 1px solid $success;

        &:before {
            content: '';
            background: url('../assets/images/icons/icon-some.svg') no-repeat center center;
            background-size: contain;
        }
    }

    &.align-left .fake-checkbox {
        margin: 0 auto 0 0;
    }

    &.align-right .fake-checkbox {
        margin: 0 0 0 auto;
    }

    &.align-center .fake-checkbox {
        margin: 0 auto;
    }
}

.custom-select {
    position: relative;

    select {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        transition: border .2s ease;
        
        &:hover {
            border: 1px solid $grey-400;
        }
    }

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: $success transparent transparent transparent;

        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}

p.label {
    font-size: rem(13);
    line-height: 1;
    color: $black;
    font-family: $font-semibold;
    margin: 15px 0 60px;

    .description {
        font-family: $font-regular;
        font-size: rem(9);
        font-style: italic;
        color: $grey-400;
        padding-left: 3px;
    }

    &[data-required='true'] span:not(.description) {

        &:after {
            content: '*';
            color: $alert;
        }
    }
}

.multiple-rights {
    position: relative;
    margin-bottom: 10px;

    label {
        margin: 0;
    }

    .custom-checkbox input[type='checkbox'] {
        width: 30px;
        height: 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    + .btn-group {
        margin-top: 40px;
    }
}

// Group form-groups
.w-50 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    + .w-50 {
        margin-top: 30px;
    }

    + .multiple-rights {
        margin-top: 60px;
    }

    .form-group {
        max-width: calc(50% - 15px);

        &[attr-title] {
        
            &:before {
                content: attr(attr-title);
                font-size: rem(12);
                text-transform: uppercase;
                width: 100%;
                text-align: center;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, calc(-100% - 25px));
            }
        }

        + .form-group {
            margin: 0;
        }

        &:nth-of-type(1n+3) {
            margin-top: 1rem;
        }
    }
}

// Invalid field
.invalid-message {
    font-size: rem(13);
    line-height: rem(22);
    color: $alert;
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
}

// Form view only
.containers-form--view {
    $gap: 15px;
    padding-bottom: 15px;

    .multiple-rights .form-group {
        display: block;
    }

    .w-50 {
        gap: 0 30px;
    }

    .form-group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0 $gap;

        &[attr-title] {
        
            &:before {
                width: auto;
                top: 0;
                left: 0;
                text-align: left;
                transform: translateX(calc(-50% + ($gap / 2))) translateY(calc(-100% - 25px));
            }
        }

        input:not([type='checkbox']):not([type='radio']) {
            border: 0;
            padding: 0;
            color: $grey-600;
        }

        label {
            display: inline-block;
            min-width: 150px;
            margin: 0;
        }
    }
}

.search-input {
    display: flex;
    align-items: center;
    gap: 0 10px;

    input {
        height: 42px;
        min-width: 230px;
        padding: 10px 10px 10px 20px;
        border: 1px solid $grey-400;
        border-radius: 7px;

        &:placeholder {
            color: rgba($black, .2);
        }
    }
}

// DatePicket
.custom-datepicker {
    width: 100%;
    position: relative;

    &:before,
    &:after {
        content: '';
        user-select: none;
        pointer-events: none;
    }

    // Thin border
    &:before {
        width: 1px;
        height: calc(100% - 14px);
        position: absolute;
        top: 7px;
        right: 50px;
        background: #F0F2F4;
    }

    // Triangle
    &:after {
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: $success transparent transparent transparent;
    }

    input:not([type='checkbox']):not([type='radio']) {
        padding: 10px 50px 10px 20px;
        cursor: pointer;
    }

    // Picker custom style
    .react-datepicker-popper {
        z-index: 5;
    }

    .react-datepicker {
        color: $black;
        font-family: $font-regular;
        position: relative;
        z-index: 5;

        &__day--keyboard-selected,
        &__day--selected,
        &__day--in-selecting-range,
        &__day--in-range {
            background: $success;

            &:hover {
                background: $success-hover;
            }

            &:active {
                background: $black;
            }
        }

        &__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range) {
            background: rgba($success, .5);
        }
    }
}
