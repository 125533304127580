/*
  BREAKPOINTS
*/

$screen-xxl: 1140px;
$screen-lg: 992px;
$screen-md: 770px;
$screen-sm: 576px;


/*
  COLORS
*/

$primary: #151515;
$alert: #ED1A3A;
$success: #5D8A88;
$success-hover: #324A49;
$black: $primary;
$background-admin: #FBFAF8;

/*
  GREYS
*/
$grey-800: #484848;
$grey-600: #979797;
$grey-500: #6B7897;
$grey-400: #CED3DE;
$grey-300: #F0F2F4;
$grey-200: #F8F9FA;

/*
  GRADIENTS
*/
