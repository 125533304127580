/*
    FONT METROPOLIS
*/

$font-light: 'Metropolis Light';
$font-regular: 'Metropolis Regular';
$font-medium: 'Metropolis Medium';
$font-semibold: 'Metropolis Semibold';
$font-bold: 'Metropolis Bold';

// 300 - light
@font-face {
    font-family: $font-light;
    src: url('./fonts/metropolis/Metropolis-Light.otf') format('opentype'),
         url('./fonts/metropolis/metropolis-light-webfont.woff') format('woff'),
         url('./fonts/metropolis/metropolis-light-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// 400 - regular
@font-face {
    font-family: $font-regular;
    src: url('./fonts/metropolis/Metropolis-Regular.otf') format('opentype'),
         url('./fonts/metropolis/metropolis-regular-webfont.woff') format('woff'),
         url('./fonts/metropolis/metropolis-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// 500 - medium
@font-face {
    font-family: $font-medium;
    src: url('./fonts/metropolis/Metropolis-Medium.otf') format('opentype'),
         url('./fonts/metropolis/metropolis-medium-webfont.woff') format('woff'),
         url('./fonts/metropolis/metropolis-medium-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// 600 - semibold
@font-face {
    font-family: $font-semibold;
    src: url('./fonts/metropolis/Metropolis-SemiBold.otf') format('opentype'),
         url('./fonts/metropolis/metropolis-semibold-webfont.woff') format('woff'),
         url('./fonts/metropolis/metropolis-semibold-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// 700 - bold
@font-face {
    font-family: $font-bold;
    src: url('./fonts/metropolis/Metropolis-Bold.otf') format('opentype'),
         url('./fonts/metropolis/metropolis-bold-webfont.woff') format('woff'),
         url('./fonts/metropolis/metropolis-bold-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
