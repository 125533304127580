/*
    BUTTONS
*/

button {
    border-radius: 0; // Prevent iOS default radius
    border: 0;
    background: none;
    padding: 0;

    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

.btn {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: rem(16);
    text-decoration: none;
    transition: background .2s ease;

    &-login,
    &-login--back {
        padding: 0 40px;
        height: 43px;
        line-height: 41px;
        border-radius: 40px;
        color: $black;
        border: 1px solid $black;
        box-shadow: none !important; // cancel SweetAlert inline style

        &:hover {
            color: white;
            background: $black;
            border: 1px solid $black;
        }

        &:disabled,
        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            border: 1px solid $grey-300;
            color: $grey-400;
        }
    }

    &-loading {

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
        }

        span {
            opacity: 0;
        }
    }

    // Animation specific
    &-login--back {
        opacity: 0;
        user-select: none;
        pointer-events: none;
        transform: translateY(-100%) scale(0.5);
        transition: transform .3s ease-out .2s, opacity .1s ease-in-out .2s;

        &.show {
            opacity: 1;
            user-select: auto;
            pointer-events: initial;
            transform: translateY(-100%) scale(1);
        }
    }

    &-back {
        width: 40px;
        height: 40px;
        border-radius: 0;
        transition: opacity .2s ease;

        &:hover {
            opacity: .7;
        }
    }

    &-cancel {
        padding: 0 40px;
        height: 43px;
        line-height: 41px;
        border-radius: 40px;
        color: $alert;
        border: 1px solid $grey-300;

        &:hover {
            color: white;
            background: $black;
            border: 1px solid $black;
        }

        &:disabled,
        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            border: 1px solid $grey-300;
            color: $grey-400;
        }
    }

    &-csv {
        padding: 0 10px;
        display: inline-flex;
        gap: 0 10px;
        align-items: center;
        justify-content: center;
        color: $black;
        font-size: rem(13);
        line-height: 33px;
        border-radius: 40px;

        &:hover {
            background: $black;
            color: white;

            svg {
                fill: white;
            }
        }

        &:active {
            background: $grey-800;
        }
    }

    &-square {
        width: 33px;
        height: 33px;
        border: 1px solid $grey-300;
        text-align: center;
        border-radius: 3px;

        &:hover {
            border: 1px solid $grey-600;

            svg {
                fill: $grey-600;
            }
        }

        &:active,
        &.active {
            border: 1px solid $black;
            background: $black;
            
            svg {
                fill: white;
            }
        }

        &.active {
            pointer-events: none;
            cursor: default;
        }
    }

    &-rounded {
        width: 55px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-radius: 50%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);

        &-containers {
            width: auto;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 10px;
            font-size: rem(13);
            line-height: rem(40);
            border-radius: 40px;
            background: $success;
            color: white;
            box-shadow: none;
            padding: 0 15px;

            svg {
                fill: white;
            }

            &.disabled {
                width: 40px;
                border-radius: 50%;
                gap: 0;
                text-indent: -9999em;
                background: $grey-200;
                padding: 0;

                svg {
                    fill: $grey-400;
                }
            }

            &:hover {
                background: $success-hover;
            }

            &:active {
                background: $black;
            }
        } 

        &.fake-btn {
            color: white;
            font-size: rem(18);
            text-transform: uppercase;
            background: #AEC4C3;
            cursor: initial;
            box-shadow: none;
        }
    }

    &-logout {
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: $alert;

            svg {
                fill: #FFFFFF;
            }
        }
    }

    &-filter {
        display: flex;
        align-items: center;
        gap: 0 5px;
        font-size: rem(13);
        line-height: rem(38);
        padding: 0 25px;
        height: 40px;
        border-radius: 40px;
        color: $black;
        background: white;
        border: 1px solid $black;

        svg {
            max-width: 14px;
            max-height: 14px;
        }

        &:hover {
            color: white;
            background: $black;

            svg {
                fill: white;
            }
        }

        &:active {
            background: $grey-600;
            border: 1px solid $grey-600;
        }

        &.btn-delete {
            background: white;
            color: $black;

            &:hover {
                color: white;
                background: $black;
                border: 1px solid $black;
            }

            &:active {
                background: $grey-600;
                border: 1px solid $grey-600;
            }
        }

        &.btn-full {
            background: $black;
            color: white;

            &:hover {
                background: $grey-800;
                color: white;
                border: 1px solid $grey-800;
            }

            &:active {
                background: $grey-600;
                border: 1px solid $grey-600;
            }
        }
    }

    &-pagination {
        width: 40px;
        height: 40px;

        &.disabled,
        [disabled] {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {

            svg {
                fill: darken($grey-400, 15%);
            }
        }
    }

    &-toggle {
        width: 100%;
        height: 100%;
        font-size: rem(13);
        font-family: $font-semibold;
        text-align: left;
        padding: 0;

        display: flex;
        align-items: center;
        gap: 0 5px;

        svg {
            fill: rgba($success, .7);
            transition: transform .2s ease;
        }

        &:hover {
            svg {
                fill: $success;
            }
        }

        &[data-toggle='true'] {
            svg {
                fill: $success;
                transform: scale(-1);
            }
        }
    }

    &-table-success {
        position: relative;
        font-size: rem(13);
        padding: 0 24px;
        line-height: 33px;
        border-radius: 40px;
        color: white;
        background: $success;

        &:hover {
            background: $success-hover;
            text-shadow: rem(0.4) 0 0 white;
        }

        &:active {
            background: $black;
        }
    }

    &.disabled {
       cursor: not-allowed;
       pointer-events: none;
    }
}

// Buttons placement
.btn-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0 15px;

    &.align-center {
        align-items: center;
    }

    &.align-start {
        align-items: flex-start;
    }

    &.align-end {
        align-items: flex-end;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-start {
        justify-content: flex-start;
    }

    &.justify-end {
        justify-content: flex-end;
    }

    &.nowrap {
        flex-wrap: nowrap;
    }
}

// Modal

.modal {

    .btn.btn-success {
        padding: 0 40px;
        height: 43px;
        line-height: 41px;
        border-radius: 40px;
        color: white;
        background: $black !important;
        border: 1px solid $black !important;
        box-shadow: none !important;

        &:hover {
            color: white;
            border: 1px solid $grey-800 !important;
            background: $grey-800 !important;
        }

        &:active {
            color: white;
            border: 1px solid $grey-600 !important;
            background: $grey-600 !important;
        }
    }
}
