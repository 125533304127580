/*
    TITLES
*/

.title-grp {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0 30px;

    a,
    button {
        position: relative;
        text-decoration: none;
        color: $black;

        &:before {
            content: '';
            height: 2px;
            position: absolute;
            top: 100%;
            left: 0;
            background: $success;
            width: 0;
            transition: width .2s ease, color .2s ease;
        }

        &:hover {
            color: $success;

            &:before {
                width: 100%;
            }
        }

        &[data-active='true'] {
            color: $success;
            pointer-events: none;
            cursor: default;

            &:before {
                width: 100%;
            }
        }
    }
}
