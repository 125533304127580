@import "../../assets/vars";
@import "../../assets/functions";
@import "../../assets/fonts";

/*
    SIDEBAR
*/

$sidebar-hover-background: rgba(93, 138, 136, 0.05);
$sidebar-hover-color: #5D8A88;
$sidebar-click-background: rgba(93, 138, 136, 0.3);

.sidebar {
  max-width: 282px;
  padding: 0 30px;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  &-top {
    height: 110px;
    width: 100%;
    overflow: hidden;

    svg {
      transform: scale(1.25);
    }
  }

  &-menu {
    padding: 30px 0;

    li {
      width: 100%;
      height: 60px;
      position: relative;

      a {
        display: flex;
        align-items: center;
        padding: 10px 30px 10px 70px;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        font-family: $font-semibold;
        font-size: rem(15);
        line-height: 1;
        color: $black;
        text-decoration: none;

        .icon {
          width: 24px;
          height: 24px;
          display: block;
          position: absolute;
          top: 50%;
          left: 30px;
          transform: translateY(-50%);
        }

        &:hover:not(.active) {
          background: $sidebar-hover-background;
          color: $sidebar-hover-color;

          svg {
            fill: $sidebar-hover-color;
          }
        }

        &:active:not(.active) {
          background: $sidebar-click-background;
          color: white;

          svg {
            fill: white;
          }
        }

        svg {
          fill: $grey-400;
        }

        &.active {
          background: $black;
          color: white;
  
          svg {
            fill: white;
          }
        }
  
      }
    }
  }

  .copyright {
    margin-top: auto;
  }
}
