@import '../../assets/vars';
@import '../../assets/functions';
@import '../../assets/fonts';

/*
    HEADER
*/

$sidebar-width: 282px;

header {
    max-width: calc(100% - #{$sidebar-width});
    background: $background-admin;
    width: 100%;
    height: 110px;
    padding: 10px 70px 10px 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    top: 0;
    left: $sidebar-width;
    z-index: 1;

    .header-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 0 10px;

        h1 {
            font-family: $font-semibold;
            font-size: rem(30);
            line-height: 1;
            margin: 0;
        }
    }
}
