$sidebar-width: 282px;
$header-height: 110px;

.container {
	width: 100%;
	padding: #{$header-height} 70px 0 calc(#{$sidebar-width} + 40px);
	position: relative;
	background: $background-admin;
}

.container-home {
	width: 100%;
	height: 100vh;
	padding: 0;
	background: white;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}

// Main container
.main-container {
	width: 100%;
	margin: 0;
	padding-bottom: 30px;

	&-btn {
		position: relative;
		margin: 10px 0 30px;
		width: 100%;
	}

	.containers {
		width: 100%;
		padding: 15px 30px;
		background: white;
		border-radius: 30px;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

		// .containers followed by .containers
		&+.containers {
			margin-top: 30px;
		}

		&-top {
			width: 100%;
			min-height: 42px;
			flex-wrap: wrap;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 15px;

			&[data-sticky='true'] {
				position: sticky;
				top: 110px;
				background: white;
				z-index: 1;
			}

			&-clothe {
				width: 100%;
				height: 300px;
				border-radius: 10px;
				margin-top: 24px;
				margin-bottom: 15px;
				background: $background-admin;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		&-title {
			font-size: rem(22);
			font-family: $font-semibold;
			margin: 0;

			&::first-letter {
				text-transform: uppercase;
			}

			span {
				font-family: $font-regular;
				font-size: rem(15);
				color: $success;

				&:before {
					content: ' - '
				}
			}
		}

		&+.container {
			margin-top: 30px;
		}
	}
}

.containers-top[data-sticky='true'] + .containers-body > .table {
	thead {
		position: sticky;
		top: 152px;
		background: white;
		z-index: 2;
	}
}
