/*
    ANIMATIONS
*/

// FadeIn 0 => 1
@keyframes fadeIn {
    // 0%
    from { 
        opacity: 0;
    }
    // 100%
    to {
        opacity: 1;
    }
}

// FadeOut 1 = > 0
@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
