/*
    TABLE
*/

.table {
    width: 100%;
    table-layout: auto;
    border: 0;
    border-radius: 0;
    border-spacing: 0;

    th {
        font-size: rem(13);
        line-height: 1;
        height: 55px;
        font-family: $font-semibold;
        text-align: left;
        padding: 0 10px;
        
        &:last-child {
            padding: 0 20px 0 10px;
        }

        &:first-child {
            padding: 0 10px 0 20px;
        }
    }

    td {
        font-family: $font-regular;
        font-size: rem(13);
        line-height: 1;
        height: 54px;
        padding: 0 10px;
        border-bottom: 1px solid $grey-300;
        cursor: pointer;
        transition: background .2s ease;

        &:last-child {
            padding: 0 20px 0 10px;
        }

        &:first-child {
            padding: 0 10px 0 20px;
        }

        &[data-cursor='off'] {
            cursor: default;
        }

        &[data-disabled='true'] {
            cursor: default;
            pointer-events: none;
            background: lighten($grey-200, 1%);
        }

        a {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            text-decoration: none;
            color: $black;
        }
    }

    .table-color {
        display: flex;
        align-items: center;

        :first-letter {
            text-transform: uppercase;
        }

        .color-sample {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 5px;
            border: 1px solid $grey-300;
        }

        .color-value {

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .management-container {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .rights-number {
        font-family: $font-semibold;
        display: inline-block;
        line-height: rem(30);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $black;
        color: white;
        text-align: center;
        position: relative;

        &:hover + .roles-list {
            opacity: 1;
            transform: translateY(0);
        }

        &:before {
            content: '...';
            position: absolute;
            bottom: 0;
            left: 0;
            color: $black;
            transform: translateX(calc(-100% - 5px));
        }
    }

    // Active row
    [data-active='true'] {
        background: $grey-300;
    }

    // Not admin and not selected
    [data-active='false']:hover {
        background: $grey-200;
    }

    // I'm the Admin ! I've got the power (not selectable, no hover)
    [data-me='true'] {
        background: $background-admin;

        .custom-checkbox {
            display: none;
        }

        &:hover {
            background: $background-admin;
        }
    }

    // Table list
    &-list {

        .table-image {
            width: 35px;
            height: 35px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    // Table card
    &-card {

        thead {
            display: none;
        }

        tbody {
            display: flex;
            width: 100%;
            gap: 10px;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;

            tr {
                position: relative;
                width: calc(100% / 5 - 10px);
                transition: opacity .2s ease;

                &:hover {
                    opacity: .8;
                }
            }

            td {
                display: block;
                height: auto;
                padding: 0;
                border: 0;

                &:first-child,
                &:last-child {
                    padding: 0;
                }

                &:not([class*='table-']) {
                    display: none;
                }

                .table-image {
                    width: 100%;
                    height: 200px;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                &.table-date {
                    position: absolute;
                    width: calc(100% - 2px);
                    padding: 10px;
                    bottom: 1px;
                    left: 1px;
                    background: rgba(white, .9);
                    color: $black;
                }

                &.table-choosen {
                    display: none;
                }
            }
        }
    }
}

// Roles list
.roles-list {
    padding: 10px 20px;
    background: rgba($black, .8);
    min-width: 200px;
    text-align: right;
    border-radius: 10px;
    position: absolute;
    top: 11px;
    right: 0;
    user-select: none; // prevent user from interacting with it while invisible
    pointer-events: none;
    transform: translateY(-5px);
    opacity: 0;
    transition: opacity .1s ease, transform .25s ease;
    will-change: opacity, transform; // /!\ use with caution /!\ ==> utiliser avec des cochons
    z-index: 2;

    .role-element {
        width: 100%;
        color: white;
        font-size: rem(13);
        line-height: 1;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}
