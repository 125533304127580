@import '../assets/vars';
@import '../assets/functions';

/*
    COPYRIGHT
*/

.copyright {
    max-width: 480px;
    width: 100%;
    align-self: flex-end;
    margin: 0 auto;
    padding-bottom: 30px;
    font-size: rem(13);
    color: rgba($grey-500, .3);
}

.container-home .copyright {
    text-align: center;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
