@import "./vars";
@import './_functions';
@import './fonts';

/*
    MODAL
*/

.modal {
  width: 100% !important;
  max-width: 650px !important;
  border-radius: 5px !important;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);

  h2 {
    font-size: rem(24) !important;
    color: $black;
    text-transform: uppercase !important;
    font-family: $font-semibold;
  }

  .modal-message {
    font-size: rem(16);
    line-height: rem(22);
    margin-bottom: 15px;
    white-space: pre-wrap;
  }

  &-list {
    list-style-type: none;

    li {
      font-size: rem(16);
      line-height: rem(22);
      font-family: $font-semibold;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
