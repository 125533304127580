/*
    MIXINS
*/

// Generate classes automatically
@mixin generate($prefix, $property) {
    $sizes: 0, .25, .5, 1, 1.5, 3;
    $spacing: 1rem;

    @for $i from 1 through length($sizes) {
        $size: nth($sizes, $i);

        .#{$prefix}-#{$i - 1} {
            #{$property}: calc($spacing * $size);
        }
    }
}

// class .mt => margin-top
@include generate(mt, margin-top);
